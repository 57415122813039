<template>
    <main class="p-home">
        <section class="hero" v-if="content">
            <img :src="content.heroImageUrl" alt="" />
            <h1 class="hero__title">{{ content.heroTitle }}</h1>
            <div class="hero__subtitle">{{ content.heroSubtitle }}</div>
        </section>

        <div id="arrow-scroll" @click="scrollToSlider" :class="arrowScrollClass">
            <transition name="quick-fade" mode="out-in">
                <span :key="arrowScrollText" class="arrow-scroll__text">{{ arrowScrollText }}</span>
            </transition>
            <span class="arrow-scroll__arrow">
                <svg height="99" viewBox="0 0 69 99" width="69" xmlns="http://www.w3.org/2000/svg"><path d="m27.9166667 67h11.1666666v77.460432l27.9166667-28.611511v14.654676l-33.5 33.496403-33.5-32.798561v-15.352518l27.9166667 28.611511z" fill="none" stroke="#d8d8d8" transform="translate(1 -66)"/></svg>
            </span>
        </div>

        <section class="projects" v-if="content">
            <div v-for="(project, index) in content.featuredProjects" :key="project.id" class="project" :class="'project-' + (index + 1)" :data-project-nb="index + 1">
                <div class="project-image" >
                    <div class="project-image__main">
                        <img :src="project.featuredImageUrl" alt="">
                        <div class="project-cta outline-cta">{{ $t('projects.discoverCta') }}<div class="outline-cta__arrow">
                                <svg height="22" viewBox="0 0 31 22" width="31" xmlns="http://www.w3.org/2000/svg"><path d="m200.75 9h3.5v23.9568345l8.75-8.8489208v4.5323741l-10.5 10.3597122-10.5-10.1438849v-4.7482014l8.75 8.8489208z" fill="none" stroke="#d8d8d8" stroke-width=".7" transform="matrix(0 -1 1 0 -8.5 213.5)"/></svg>
                            </div>
                        </div>
                        <router-link :to="project.uri" class="project-image__main__overlay-cta"></router-link>
                        <div class="project-image__main__overlay"></div>
                    </div>
                    <img class="project-image__shadow" :src="project.featuredImageShadowUrl !== ''? project.featuredImageShadowUrl : project.featuredImageUrl" alt="">
                </div>
                <div class="project-info">
                    <router-link :to="project.uri" class="project-info__title">{{ project.title }}</router-link>
                    <div class="project-info__date">{{ $t('projects.realised') + project.realisationDate }}</div>
                </div>
            </div>
        </section>

        <section class="container slider-section" id="slider-section" v-if="content">
            <Slider :slides="content.slider"></Slider>
        </section>
    </main>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Slider from '@/components/Slider';

export default {
    name: 'Home',

    components: {
        Slider
    },

    data() {
        return {
            content: null,
            arrowScrollText: this.$t('home.scrollCta'),
            arrowScrollClass: ''
        }
    },

    created() {
        this.$craft.getContentBySlug(this.$t('nav.home.slug'))
            .then(res => {
                this.content = res.data;
                if (res.data.seo) {
                    this.$store.commit('SET_SEO', res.data.seo);
                }
            })
            .then(() => {
                this.initAnimations();
            })
            .then(() => {
                this.$store.commit('SET_OTHER_LOCALES_URLS', this.content.otherLocalesUrls);
                this.$store.commit('SET_DATA_READY', true);
            });
    },

    updated() {
        ScrollTrigger.refresh(true);
    },

    methods: {
        initAnimations() {
            gsap.utils.toArray('.project-image__main').forEach(project => {
                const cta = project.querySelector('.project-cta');
                project.addEventListener('mousemove' , function(e){
                    let projectarea = project.getBoundingClientRect();
                    let posX = e.clientX - projectarea.left;
                    let posY = e.clientY - projectarea.top;

                    cta.style.left = posX - 100 + 'px';
                    cta.style.top = posY - 28 + 'px';
                })
            });

            ScrollTrigger.create({
                trigger: '#arrow-scroll',
                endTrigger: '#slider-section',
                start: 'top 300px',
                end: 'center center',
                pin: true,
                pinSpacing: false,
                onEnter: () => {
                    this.arrowScrollText = this.$t('home.scrollCtaAfter');
                    this.arrowScrollClass = '';
                },
                onLeave: () => {
                    this.arrowScrollClass = 'animation-end';
                },
                onEnterBack: () => {
                    this.arrowScrollClass = '';
                },
                onLeaveBack: () => {
                    this.arrowScrollText = this.$t('home.scrollCta');
                },
            });


            gsap.utils.toArray('.project').forEach((project) => {
                let shadowImage = project.querySelector('.project-image__shadow');

                if (project.dataset.projectNb == 2) {
                    gsap.from(shadowImage, {
                        opacity: 0,
                        x: 200,
                        duration: 1,
                        ease: 'Power1.inOut',
                        scrollTrigger: {
                            trigger: project,
                            start: 'top 70%',
                            end: 'bottom bottom',
                        },
                    });
                }
                else {
                    gsap.from(shadowImage, {
                        opacity: 0,
                        x: -200,
                        duration: 1,
                        ease: 'Power1.inOut',
                        scrollTrigger: {
                            trigger: project,
                            start: 'top 70%',
                            end: 'bottom bottom',
                        },
                    });
                }

                const img = project.querySelector('.project-image__main img');
                gsap.fromTo(img, {
                    y: -50,
                }, {
                    y: 0,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: img,
                        start: 'top center',
                        end: '+=100%',
                        scrub: true,
                    },
                });
            });

            ScrollTrigger.refresh(true);
        },


        scrollToSlider() {
            const sliderSection = document.getElementById('slider-section');
            sliderSection.scrollIntoView({ block: 'start', behavior: 'smooth' });
        },

    }
};
</script>
