<template>
    <div class="slider-wrapper">
        <VueSlickCarousel v-bind="settings" @reInit="reInit">
            <template #customPaging="page">
                <div class="dot-slide-name">
                    {{ $t('home.slide-' + page) }}
                </div>
            </template>
            <div class="slide" v-for="(slide, index) in slides" :key="slide.id">
                <img :src="slide.slideImageUrl" alt="" />
                <div class="slide__text">
                    <div v-html="slide.slideTitle" class="slide__text__title"></div> 
                    <div class="slide__text__description">{{ slide.slideDescription }}</div>
                </div>
                <Cta :type="'outline'" :ctaText="slide.slideCtaText" :ctaUrl="slideCtaUrl[index]" />
            </div>
        </VueSlickCarousel>
    </div>
</template>

<script>
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

import Cta from '@/components/Cta';

export default {
    name: 'Slider',

    props: {
        slides: { type: Array, required: true },
    },

    data() {
        return {
            settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1
            },
            slideCtaUrl: {
                0: this.$t('nav.approach.uri'),
                1: this.$t('nav.workshop.uri'),
            }
        }
    },

    components: {
        VueSlickCarousel,
        Cta
    },

    mounted() {
        ScrollTrigger.refresh(true);
    },

    methods: {
        reInit() {
            ScrollTrigger.refresh(true);
        }
    }
}
</script>
