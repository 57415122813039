<template>
    <router-link v-if="type == 'outline'" class="outline-cta" :to="ctaUrl">{{ ctaText }}<span class="outline-cta__arrow">
            <svg height="22" viewBox="0 0 31 22" width="31" xmlns="http://www.w3.org/2000/svg">
                <path d="m200.75 9h3.5v23.9568345l8.75-8.8489208v4.5323741l-10.5 10.3597122-10.5-10.1438849v-4.7482014l8.75 8.8489208z" fill="none" stroke="#d8d8d8" stroke-width=".7" transform="matrix(0 -1 1 0 -8.5 213.5)"/>
            </svg>
        </span>
    </router-link>

    <div v-else-if="type == 'big'" class="big-cta">
        <a :href="ctaUrl">{{ ctaText }}</a>
    </div>

    <a v-else class="secondary-cta" :href="ctaUrl">{{ ctaText }}</a>
</template>

<script>
export default {
    name: 'Cta',

    props: {
        type: { default: 'title', type: String },
        id: { type: String },
        ctaUrl: { default: '#', type: String },
        ctaText: { default: '', type: String },
    },
};
</script>
